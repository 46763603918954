<template>
	<div>
		<v-dialog
			v-model="model"
			persistent
			width="400"
		>
			<strong>저장 완료!</strong>
			<p>사진 저장이 완료되었습니다. 내용 확인 후 종료해주세요.</p>
			<v-btn>확인</v-btn>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			model: true,
		}
	},
}
</script>
